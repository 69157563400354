import React from 'react'

import { ReactNode } from 'react'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useMatch,
  useMatches,
  useResolvedPath,
  useParams,
  useLocation,
  matchPath,
  useNavigate,
} from 'react-router-dom'

import Link from '../Link'

// needed as export for react-native
const BackButton = ({ children }: { children?: ReactNode }) => <>{children || null}</>

export {
  Router,
  Routes,
  Route,
  Link,
  Navigate,
  useMatch,
  useMatches,
  useResolvedPath,
  useLocation,
  useParams,
  matchPath,
  BackButton,
  useNavigate,
}
