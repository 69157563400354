import React, { CSSProperties } from 'react'
import { Link as LinkImport } from 'react-router-dom'
import ColoredText from '../../CustomText/ColoredText'

interface IProps {
  to: string
  children?: React.ReactNode
  style?: CSSProperties
  onPress?: () => void
}

const Link = ({ to, children, ...props }: IProps) => {
  // It is intended to be an external link
  if (/^https?:\/\//.test(to)) {
    return (
      <a href={to} target="__blank" {...props} style={{ ...props.style, textDecoration: 'none' }}>
        {typeof children === 'string' ? (
          <ColoredText style={{ textDecorationLine: 'underline' }}>{children}</ColoredText>
        ) : (
          children
        )}
      </a>
    )
  }

  // Finally, it is an internal link
  return (
    <LinkImport to={to} {...props} style={{ ...props.style }}>
      {typeof children === 'string' ? (
        <ColoredText style={{ textDecorationLine: 'underline' }}>{children}</ColoredText>
      ) : (
        children
      )}
    </LinkImport>
  )
}

export default Link
